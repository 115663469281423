import React, { Fragment, useEffect, useState } from "react"
import getParams from "utils/getParams"
import { observer } from "mobx-react"
import OperationStore from "stores/OperationStore/List"
import Breadcrumbs from "pages/Operations/components/Breadcrumbs"
import { Card, Paginate, BreadcrumbItem } from "components/UI"
import { historyPushFromStore } from "utils/historyPushWithStore"
import Table from "./components/Table"
import Filter from "./components/Filter"
import useSelectAll from "hooks/useSelectAll"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/UserSalePlans/components/Tabs"

const budgetStore = BudgetStore.create()
const operationStore = OperationStore.create()

const List = (props) => {
  const { id, budget_id } = props.match.params
  const [defaultParams, setDefaultParams] = useState({})
  useEffect(() => {
    async function fetchData() {
      await budgetStore.fetch({ id: budget_id })

      historyPushFromStore(props.history, window.location.pathname)
      setDefaultParams({
        by_status: 1,
        by_operation_kind: 0,
        by_subdivision_id: budgetStore.data.subdivision.id,
        by_year: budgetStore.data.year,
        by_seven_hundred_one: true
      })
    }
    fetchData()
  }, [id, budget_id])

  useEffect(() => {
    operationStore.fetch({ ...getParams(), filter: defaultParams })
  }, [defaultParams])

  const [
    selectedRows,
    totalSelectedRow,
    isCheckedRow,
    onCheckedRow,
    isCheckedAllRows,
    onCheckedAllRows,
    resetSelected
  ] = useSelectAll(operationStore.data.toJSON())

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}/user_sale_plans`}>План продаж</BreadcrumbItem>
        <BreadcrumbItem active>ОР факт</BreadcrumbItem>
      </Breadcrumbs>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <Card>
        <Filter store={operationStore} resetSelected={resetSelected} defaultParams={defaultParams} />
      </Card>
      <Table
        store={operationStore}
        selectedRows={selectedRows}
        totalSelectedRow={totalSelectedRow}
        isCheckedRow={isCheckedRow}
        onCheckedRow={onCheckedRow}
        isCheckedAllRows={isCheckedAllRows}
        onCheckedAllRows={onCheckedAllRows}
        resetSelected={resetSelected}
      />
      <Paginate store={operationStore} additionalParams={{ filter: defaultParams }} />
    </Fragment>
  )
}

export default observer(List)
