import React, { useContext } from "react"
import { Destroy, Edit } from "components/UI"
import afterDestroyFetch from "utils/afterDestroyFetch"
import { observer } from "mobx-react"
import formatDate from "utils/formatDate"
import AuthStoreContext from "contexts/AuthStoreContext"
import { withRouter } from "react-router-dom"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import classnames from "classnames"

const first_level_bill_name = (i) => {
  if (i.kind.value?.toString() === "2") {
    return "Перемещение"
  }
  switch (i.operation_kind.value.toString()) {
    case "0":
      return i.bill.first_level_bill_name
    default:
      return i.balance_bill.first_level_bill_name
  }
}

const second_level_bill_name = (i) => {
  if (i.kind.value?.toString() === "2") {
    return i.moving_kind.label
  }
  switch (i.operation_kind.value.toString()) {
    case "0":
      return i.bill.second_level_bill_name
    default:
      return i.balance_bill.second_level_bill_name
  }
}

const Table = ({
  history,
  store,
  selectedRows,
  totalSelectedRow,
  isCheckedRow,
  onCheckedRow,
  isCheckedAllRows,
  onCheckedAllRows,
  resetSelected
}) => {
  const { data: currentUser } = useContext(AuthStoreContext)

  const { page } = store.meta.pagination

  const coming_sum = Object.values(selectedRows)
    .flat()
    .filter((i) => i.kind.value.toString() === "0")
    .map((i) => parseMoney(i.cost))
    .reduce((a, b) => a + b, 0)
  const consumption_sum = Object.values(selectedRows)
    .flat()
    .filter((i) => i.kind.value.toString() === "1")
    .map((i) => parseMoney(i.cost))
    .reduce((a, b) => a + b, 0)
  const balance = coming_sum - consumption_sum

  return (
    <table className="table table-hover">
      <thead className="thead-light">
        <tr>
          <th className="actions one">
            <input type="checkbox" checked={isCheckedAllRows(page)} onChange={(e) => onCheckedAllRows(e, page)} />
          </th>
          <th>ID</th>
          <th>Статус</th>
          <th>Основной счет</th>
          <th>Суб-счет 1</th>
          <th>Суб-счет 2</th>
          <th>Контрагент</th>
          <th style={{ width: "150px" }}>Сумма</th>
          <th>Дата</th>
          <th>Назначение платежа</th>
          <th scope="col" className="actions two" />
        </tr>
      </thead>
      <tbody>
        {store.data.map((i, index) => (
          <tr
            key={`operations-${index}`}
            className={["table-success", "table-danger", "table-warning", "table-info"][parseInt(i.kind.value)]}
          >
            <td>
              <input type="checkbox" checked={isCheckedRow(i, page)} onChange={() => onCheckedRow(i, page)} />
            </td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.id}</td>
            {i.status.value.toString() === "4" && (
              <td onClick={() => history.push(`/operations/${i.id}`)}>
                <span style={{ color: "red" }}>{i.status.label}</span>
              </td>
            )}
            {i.status.value.toString() === "5" && (
              <td onClick={() => history.push(`/operations/${i.id}`)}>
                <span
                  className={classnames("badge", {
                    "badge-success": i.payment_plan_id,
                    "badge-danger": !i.payment_plan_id
                  })}
                >
                  {i.status.label}
                </span>
              </td>
            )}
            {i.status.value.toString() !== "4" && i.status.value.toString() !== "5" && (
              <td onClick={() => history.push(`/operations/${i.id}`)}>{i.status.label}</td>
            )}
            <td onClick={() => history.push(`/operations/${i.id}`)}>{first_level_bill_name(i)}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{second_level_bill_name(i)}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>
              {i.operation_kind.value.toString() === "0"
                ? i.bill.third_level_bill_name
                : i.balance_bill.third_level_bill_name}
            </td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.counterpart.name} {i.is_selfemployed && 'Самозанятый'}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{i.cost}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>{formatDate(i.date_on)}</td>
            <td onClick={() => history.push(`/operations/${i.id}`)}>
              {i.comment + (i.kind.value?.toString() === "2" ? ` / ${i.moving_kind.label}` : "")}
            </td>
            <td>
              <Edit url={`/operations/${i.id}/edit`} />
              <Destroy
                action={() => afterDestroyFetch(store, i.id)}
                disabled={
                  !(
                    i.status.value.toString() !== "1" &&
                    (currentUser.id === 5 ||
                      currentUser.id == 48 ||
                      (currentUser.subdivision.id.toString() === "7" && i.creator?.subdivision?.id?.toString() === "7"))
                  )
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className="thead-light">
        <tr>
          <th colSpan="10">
            Выбрано: {totalSelectedRow} | Сумма: {formatMoney(balance)}
          </th>
          <th>
            <button
              type="button"
              className="btn btn-danger"
              title="Сбросить выделение"
              onClick={resetSelected}
              disabled={totalSelectedRow === 0}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  )
}

export default withRouter(observer(Table))
