import React, { Fragment, useEffect, useState } from "react"
import {
  TextInput,
  RadioButtonGroup,
  RadioInput,
  PercentageInput,
  SelectInput,
  Submit,
  DateInput,
  CurrencyInput
} from "components/UI"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import UserSelect from "components/Common/UserSelect"
import formSubmitHandler from "utils/formSubmitHandler"
import UserSalePlanSelect from "components/Common/UserSalePlanSelect"
import SubdivisionSelect from "pages/Budgets/containers/BudgetMotivations/components/SubdivisionSelect"
import SubdivisionsSelect from "pages/Budgets/containers/BudgetMotivations/components/SubdivisionsSelect"

const Form = ({ store, values, budgetStore, handleSubmit, ...props }) => {
  const { kinds, rate_kinds, statuses, periodicities, bases, conditions, user_sale_plan_kinds } = store.filtersOptions

  const [filtredSalePlanKinds, setFiltredSalePlanKinds] = useState([])
  const isCompany = budgetStore.data.kind.value.toString() === "1"

  // console.log(filtredSalePlanKinds)

  useEffect(() => {
    // console.log(budgetStore.kind.value.toString())
    if (isCompany) {
      if (store.data.id === null) {
        props.setFieldValue("user_sale_plan_kind", { value: 2 })
      }

      setFiltredSalePlanKinds([
        {
          value: 2,
          label: "План продаж компании (итого)"
        },
        {
          value: 0,
          label: "План продаж Подразделения (итого)"
        }
      ])
    } else {
      if (store.data.id === null) {
        props.setFieldValue("user_sale_plan_kind", { value: 0 })
      }

      setFiltredSalePlanKinds([
        {
          value: 0,
          label: "План продаж Подразделения (итого)"
        },
        {
          value: 1,
          label: "План продаж (персональный)"
        }
      ])
    }
  }, [values.basis.value, isCompany])

  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Статус" name="status.value">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <RadioButtonGroup label="Применять поправочные коэффициенты" name="is_use_coefficient">
        {[
          { label: "Нет", value: false },
          { label: "Да", value: true }
        ].map(({ label, value }) => (
          <RadioInput key={`is_use_coefficient_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {/* {budgetStore.data.kind.value.toString() === "0" && ( */}
      <RadioButtonGroup label="Подразделение / Сотрудник" name="kind.value">
        {kinds.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {/* )} */}
      <UserSelect name="user.id" label="Сотрудник" />
      <SelectInput name="periodicity.value" label="Периодичность выплат" options={periodicities} withEmptyValue />
      <DateInput name="start_on" label="Период действия с" />
      <DateInput name="finish_on" label="Период действия по" />
      <SelectInput
        name="basis.value"
        label="Базис расчета"
        options={bases.map((i) => (i.value == 2 ? { ...i, isDisabled: true } : i))}
        withEmptyValue
      />
      {/* { показыаем если базис расчета = план продаж} */}
      {values.basis?.value?.toString() === "0" && (
        <RadioButtonGroup label="Выбор плана продаж" name="user_sale_plan_kind.value">
          {filtredSalePlanKinds.map(({ label, value }) => (
            <RadioInput key={`kind_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
      )}

      {budgetStore.data.kind.value.toString() === "0" &&
        values.basis?.value?.toString() === "0" &&
        values.user_sale_plan_kind?.value?.toString() === "1" && <UserSalePlanSelect budget_id={budgetStore.data.id} />}

      {budgetStore.data.kind.value.toString() === "1" &&
        values.basis?.value?.toString() === "0" &&
        values.user_sale_plan_kind?.value?.toString() === "0" &&
        !isCompany && <SubdivisionSelect name="subdivision.id" by_year={budgetStore.data.year} />}

      {budgetStore.data.kind.value.toString() === "1" &&
        values.basis?.value?.toString() === "0" &&
        values.user_sale_plan_kind?.value?.toString() === "0" &&
        isCompany && <SubdivisionsSelect name="subdivisions" by_year={budgetStore.data.year} />}

      <SelectInput name="condition.value" label="Условие" options={conditions} withEmptyValue />
      <RadioButtonGroup label="Вид ставки" name="rate_kind.value">
        {rate_kinds.map(({ label, value }) => (
          <RadioInput key={`rate_kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {String(values.rate_kind.value) === "0" && <PercentageInput name="rate" label="Ставка" />}
      {String(values.rate_kind.value) === "1" && String(values.periodicity.value) == "0" && (
        <CurrencyInput name="amount" label="Сумма" />
      )}
      {String(values.rate_kind.value) === "1" && String(values.periodicity.value) == "1" && (
        <div class="row">
          <div className="col-3">
            <CurrencyInput name="quarter_1_amount" label="1 Квартал" />
          </div>
          <div className="col-3">
            <CurrencyInput name="quarter_2_amount" label="2 Квартал" />
          </div>
          <div className="col-3">
            <CurrencyInput name="quarter_3_amount" label="3 Квартал" />
          </div>
          <div className="col-3">
            <CurrencyInput name="quarter_4_amount" label="4 Квартал" />
          </div>
        </div>
      )}
      {String(values.rate_kind.value) === "1" && String(values.periodicity.value) == "2" && (
        <Fragment>
          <div class="row">
            <div className="col-3">
              <CurrencyInput name="month_1_amount" label="Январь" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_2_amount" label="Февраль" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_3_amount" label="Март" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_4_amount" label="Апрель" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_5_amount" label="Май" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_6_amount" label="Июнь" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_7_amount" label="Июль" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_8_amount" label="Август" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_9_amount" label="Сентябрь" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_10_amount" label="Октябрь" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_11_amount" label="Ноябрь" />
            </div>
            <div className="col-3">
              <CurrencyInput name="month_12_amount" label="Декабрь" />
            </div>
          </div>
        </Fragment>
      )}
      <TextInput name="comment" label="Комментарий" />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: budget_id } = formikBag.props.budgetStore.data
      formSubmitHandler({ ...values, budget_id: budget_id }, formikBag, `/budgets/${budget_id}/budget_motivations`)
    }
  }),
  observer
)(Form)
