import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { BreadcrumbItem, Button, Card } from "components/UI"
import formatDate from "utils/formatDate"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import ReportStore from "stores/ReportStore/Kvs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/Coefficient/components/Tabs"

const budgetStore = BudgetStore.create()
const reportStore = ReportStore.create()

const Show = (props) => {
  const { budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
  }, [budget_id])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Коэффициенты</BreadcrumbItem>
        <BreadcrumbItem active>Тех. параметры</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budgetStore.data.id}/edit`}>Радактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />

      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" colSpan="2">
              Технические параметры для расчета Бюджета
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{width: '50%'}}>Ставка налога с оборота (план)</td>
            <td>{budgetStore.data.coefficient_turnover}%</td>
          </tr>
          <tr>
            <td style={{width: '50%'}}>Ставка налога с оборота (факт)</td>
            <td>{budgetStore.data.coefficient_turnover_fact}%</td>
          </tr>
          <tr>
            <td>Ставка налога на ФОТ 1</td>
            <td>{budgetStore.data.coefficient_fot_one}%</td>
          </tr>
          <tr>
            <td>Ставка налога на ФОТ 2</td>
            <td>{budgetStore.data.coefficient_fot_two}%</td>
          </tr>
          <tr>
            <td>Ставка налога на ФОТ 3</td>
            <td>{budgetStore.data.coefficient_fot_three}%</td>
          </tr>
          <tr>
            <td>МРОТ</td>
            <td>{formatMoney(budgetStore.data.coefficient_mrot)}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default observer(Show)
