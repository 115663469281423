const prepare = (params = {}) => {
  
  const {
    kind: { value: kind },
    user_sale_plan_kind: { value: user_sale_plan_kind },
    rate_kind: { value: rate_kind },
    status: { value: status },
    user_sale_plan: { id: user_sale_plan_id },
    user: { id: user_id },
    periodicity: { value: periodicity },
    basis: { value: basis },
    condition: { value: condition },
    subdivision: { id: subdivision_id },
    subdivisions,
    ...other
  } = params
  return {
    ...other,
    kind: parseInt(kind),
    user_sale_plan_kind: parseInt(user_sale_plan_kind),
    rate_kind: parseInt(rate_kind),
    status,
    user_id,
    user_sale_plan_id,
    periodicity,
    basis,
    condition,
    subdivision_id,
    subdivision_ids: subdivisions.map(i => i.id),
  }
}

export { prepare }
