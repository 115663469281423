import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"

const Company = ({ budget_id, budgetStore, budgetMotivationStore }) => {
  const [accrued, setAccrued] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_motivations/accrued`).then(({ data }) => {
      setAccrued(data)
    })
  }, [])

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table table-bordered" style={{ width: "4300px" }}>
        <thead>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Мотивация</th>
            <th rowSpan={2}></th>
            <th rowSpan={2}>ИТОГО {budgetStore.data.year}</th>
            <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
            <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Подразделение/Сотрудник</th>
            <th>Метрики мотивации</th>
            <th>
              {formatMoney(
                accrued
                  .map((values) => values.months.reduce((a, b) => a + parseMoney(b), 0))
                  .reduce((a, b) => a + parseMoney(b), 0)
              )}
            </th>
            {Array(12)
              .fill(null)
              .map((_, month) => (
                <Fragment key={`item=${month}`}>
                  {month % 3 === 0 && (
                    <th style={{ verticalAlign: "middle", width: "200px" }}>
                      {formatMoney(
                        accrued.map((values) => values.quarters[month / 3]).reduce((a, b) => a + parseMoney(b), 0)
                      )}
                    </th>
                  )}
                  <th>
                    {formatMoney(accrued.map((values) => values.months[month]).reduce((a, b) => a + parseMoney(b), 0))}
                  </th>
                </Fragment>
              ))}
          </tr>
        </thead>
        <tbody>
          {accrued.map((values) => (
            <tr>
              <td>{values.id}</td>
              <td>{values.name}</td>
              <td dangerouslySetInnerHTML={{ __html: Object.values(values.metrics).join("<br />") }} />
              <td>{formatMoney(values.year)}</td>
              {values.months.map((value, month) => (
                <Fragment key={`item=${month}`}>
                  {month % 3 === 0 && (
                    <td style={{ verticalAlign: "top", width: "200px" }}>
                      {values.quarters[month / 3] != null ? formatMoney(values.quarters[month / 3]) : ""}
                    </td>
                  )}
                  <td>{value != null ? formatMoney(value) : ""}</td>
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(Company)
