import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Avr = types.model("Avr", {
  id: types.maybeNull(types.integer),
  payment_status: types.maybeNull(newCustomEnum)
})

const Kv = types.model("Kv", {
  id: types.maybeNull(types.integer),
  rate: types.maybeNull(types.string)
})

const Contract = types.model("Contract", {
  id: types.maybeNull(types.integer),
  kvs: types.maybeNull(types.array(Kv))
})

const ContractInstallment = types.model("ContractInstallment", {
  id: types.maybeNull(types.integer),
  avrs: types.array(Avr, []),
  payment_cost: types.maybeNull(types.string),
  payment_on: types.maybeNull(customDate),
  sp_payment_cost: types.maybeNull(types.string),
  sp_payment_on: types.maybeNull(customDate),
  sp_payment_kind: types.maybeNull(newCustomEnum),
  sp_sk_payment_on: types.maybeNull(customDate),
  sp_sk_payment_cost: types.maybeNull(types.string),
  sp_sk_payment_form: types.maybeNull(newCustomEnum),
  sp_sinergy_payment_on: types.maybeNull(customDate),
  sp_sinergy_payment_cost: types.maybeNull(types.string),
  sp_sinergy_payment_form: types.maybeNull(newCustomEnum),
  contract_payment_status: types.maybeNull(newCustomEnum),
  kv_by_paid_sp: types.maybeNull(types.string),
  subagent_cost: types.maybeNull(types.string),
  subagent_cost_planned_on: types.maybeNull(customDate),
  subagent_cost_fact_on: types.maybeNull(customDate),
  is_payments_equal: types.maybeNull(types.boolean),
  fix_kv_cost: types.maybeNull(types.string),
  contract: types.maybeNull(Contract),
  comment: types.maybeNull(types.string)
})

export default ContractInstallment
