import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"

const ff = (col) => {
  let total = 0
  document
    .getElementById("data")
    .querySelectorAll("tr")
    .forEach((el) => {
      total += parseMoney(el.childNodes[col].innerHTML)
    })
  document.getElementById(`total-${col - 2}`).innerHTML = formatMoney(total)
}

const ffdd = (i) => {
  if (i.user_sale_plan_kind.value.toString() === "0") {
    return i.subdivisions.map((i) => i.name).join(" / ")
  }
  if (i.user_sale_plan_kind.value.toString() === "2") {
    return "Компании (итого)"
  }
}

const Company = ({ budget_id, budgetStore, budgetMotivationStore }) => {
  useEffect(() => {
    ff(3)
    ff(4)
    ff(5)
    ff(6)
    ff(7)
    ff(8)
    ff(9)
    ff(10)
    ff(11)
    ff(12)
    ff(13)
    ff(14)
    ff(15)
    ff(16)
    ff(17)
    ff(18)
    ff(19)
  })

  const [tecResults, setTecResults] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_company_summaries/tec_results_plan`).then(({ data }) => {
      setTecResults(data.data)
    })
  }, [])

  const [finResults, setFinResults] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_company_summaries/fin_results_plan`).then(({ data }) => {
      setFinResults(data.data)
    })
  }, [])

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table table-bordered" style={{ width: "4300px" }}>
        <thead>
          <tr>
            <th rowSpan={2}>#</th>
            <th rowSpan={2}>Мотивация</th>
            <th rowSpan={2}></th>
            <th rowSpan={2}>ИТОГО {budgetStore.data.year}</th>
            <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
            <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
            <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
            <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
          </tr>
          <tr>
            <th></th>
            <th>Подразделение/Сотрудник</th>
            <th>Метрики мотивации</th>

            <td id="total-1">11</td>
            <td id="total-2"></td>
            <td id="total-3"></td>
            <td id="total-4"></td>
            <td id="total-5"></td>
            <td id="total-6"></td>
            <td id="total-7"></td>
            <td id="total-8"></td>
            <td id="total-9"></td>
            <td id="total-10"></td>
            <td id="total-11"></td>
            <td id="total-12"></td>
            <td id="total-13"></td>
            <td id="total-14"></td>
            <td id="total-15"></td>
            <td id="total-16"></td>
            <td id="total-17"></td>
          </tr>
        </thead>
        <tbody id="data">
          {budgetMotivationStore.data.filter(i => i.status.value.toString() == "1").map((values) => (
            <tr>
              <td>{values.id}</td>
              <td>{values.user.name}</td>
              <td>
                {values.periodicity.label}
                <br />
                {ffdd(values)}
                <br />
                {values.condition.label}
              </td>
              {values.rate_kind.value.toString() === "1" &&
              ["0", "1", "2"].includes(values.periodicity.value.toString()) ? (
                <Fragment>
                  {values.periodicity.value.toString() === "0" && (
                    <Fragment>
                      <td>{formatMoney(values.amount)}</td>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <Fragment key={`item=${month}`}>
                          {month % 3 === 0 && <td style={{ width: "200px" }}></td>}
                          <td>{(month + 1) % 12 === 0 ? formatMoney(values.amount) : ""}</td>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                  {values.periodicity.value.toString() === "1" && (
                    <Fragment>
                      <td>
                        {formatMoney(
                          parseFloat(values.quarter_1_amount) +
                            parseFloat(values.quarter_2_amount) +
                            parseFloat(values.quarter_3_amount) +
                            parseFloat(values.quarter_4_amount)
                        )}
                      </td>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <Fragment key={`item=${month}`}>
                          {month % 3 === 0 && (
                            <td style={{ width: "200px" }}>{formatMoney(values[`quarter_${month / 3 + 1}_amount`])}</td>
                          )}
                          <td>
                            {(month + 1) % 3 === 0 ? formatMoney(values[`quarter_${(month + 1) / 3}_amount`]) : ""}
                          </td>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                  {values.periodicity.value.toString() === "2" && (
                    <Fragment>
                      <td>
                        {formatMoney(
                          parseFloat(values.month_1_amount) +
                            parseFloat(values.month_2_amount) +
                            parseFloat(values.month_3_amount) +
                            parseFloat(values.month_4_amount) +
                            parseFloat(values.month_5_amount) +
                            parseFloat(values.month_6_amount) +
                            parseFloat(values.month_7_amount) +
                            parseFloat(values.month_8_amount) +
                            parseFloat(values.month_9_amount) +
                            parseFloat(values.month_10_amount) +
                            parseFloat(values.month_11_amount) +
                            parseFloat(values.month_12_amount)
                        )}
                      </td>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <Fragment key={`item=${month}`}>
                          {month % 3 === 0 && (
                            <td style={{ width: "200px" }}>
                              {formatMoney(
                                parseMoney(values[`month_${month + 1}_amount`]) +
                                  parseMoney(values[`month_${month + 2}_amount`]) +
                                  parseMoney(values[`month_${month + 3}_amount`])
                              )}
                            </td>
                          )}
                          <td>{formatMoney(values[`month_${month + 1}_amount`])}</td>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {values.condition.value.toString() === "0" &&
                  values.basis.value.toString() === "1" &&
                  values.rate_kind.value.toString() === "0" ? (
                    <Fragment>
                      <td>
                        {formatMoney(
                          ((finResults["ТЕХ. РЕЗ."] || [])
                            .map((i) => i.values)
                            .flat()
                            .reduce((a, b) => a + parseMoney(b), 0) *
                            parseMoney(values.rate)) /
                            100
                        )}
                      </td>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <Fragment key={`item=${month}`}>
                          {month % 3 === 0 && (
                            <td style={{ width: "200px" }}>
                              {formatMoney(
                                ((finResults["ТЕХ. РЕЗ."] || [])
                                  .map((i) => i.values.slice(month, month + 3))
                                  .flat()
                                  .reduce((a, b) => a + parseMoney(b), 0) *
                                  parseMoney(values.rate)) /
                                  100
                              )}
                            </td>
                          )}
                          <td>
                            {formatMoney(
                              ((finResults["ТЕХ. РЕЗ."] || [])
                                .map((i) => i.values[month])
                                .reduce((a, b) => a + parseMoney(b), 0) *
                                parseMoney(values.rate)) /
                                100
                            )}
                          </td>
                        </Fragment>
                      ))}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td>
                        {formatMoney(
                          ((tecResults["ПЛАН ПРОДАЖ"] || [])
                            .map((i) => i.values)
                            .flat()
                            .reduce((a, b) => a + parseMoney(b), 0) *
                            parseMoney(values.rate)) /
                            100
                        )}
                      </td>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                        <Fragment key={`item=${month}`}>
                          {month % 3 === 0 && (
                            <td style={{ width: "200px" }}>
                              {formatMoney(
                                ((tecResults["ПЛАН ПРОДАЖ"] || [])
                                  .map((i) => i.values.slice(month, month + 3))
                                  .flat()
                                  .reduce((a, b) => a + parseMoney(b), 0) *
                                  parseMoney(values.rate)) /
                                  100
                              )}
                            </td>
                          )}
                          <td>
                            {formatMoney(
                              ((tecResults["ПЛАН ПРОДАЖ"] || [])
                                .map((i) => i.values[month])
                                .reduce((a, b) => a + parseMoney(b), 0) *
                                parseMoney(values.rate)) /
                                100
                            )}
                          </td>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(Company)
