import { types } from "mobx-state-tree"
import customDate from "types/customDate"
import newCustomEnum from "types/newCustomEnum"

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BudgetItem = types.model("BudgetItem", {
  id: types.maybeNull(types.integer),
  month: types.maybeNull(types.number),
  cost: types.maybeNull(types.string)
})

const Parent = types.model("Parent", {
  id: types.maybeNull(types.integer),
  year: types.maybeNull(types.integer)
})

const Child = types.model("Child", {
  id: types.maybeNull(types.integer),
  year: types.maybeNull(types.integer)
})

const Budget = types.model("Budget", {
  id: types.maybeNull(types.integer),
  status: types.maybeNull(newCustomEnum),
  kind: types.maybeNull(newCustomEnum),
  subdivision: types.maybeNull(Subdivision),
  subdivisions: types.maybeNull(types.array(Subdivision)),
  year: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  creator: types.maybeNull(User),
  budget_items: types.maybeNull(types.array(BudgetItem)),
  created_at: types.maybeNull(customDate),
  forecast: types.maybeNull(types.string),
  coefficient_turnover: types.maybeNull(types.string),
  coefficient_turnover_fact: types.maybeNull(types.string),
  coefficient_fot_one: types.maybeNull(types.string),
  coefficient_fot_two: types.maybeNull(types.string),
  coefficient_fot_three: types.maybeNull(types.string),
  coefficient_mrot: types.maybeNull(types.string),
  parent: types.maybeNull(Parent),
  child: types.maybeNull(Child),
  integrated: types.maybeNull(types.boolean),
})

const budgetDefaultValue = {
  status: { value: 0 },
  kind: { value: 0 },
  subdivision: null,
  year: new Date().getFullYear() + 1,
  comment: ""
}

export { Budget, budgetDefaultValue }
