import React, { Fragment, useContext, useEffect, useState } from "react"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import {
  RadioButtonGroup,
  RadioInput,
  Submit,
  TextInput,
  SelectInput,
  CurrencyInput,
  PercentageInput
} from "components/UI"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import SubdivisionsSelect from "./SubdivisionsSelect"
import formSubmitHandler from "utils/formSubmitHandler"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import ReportStore from "stores/ReportStore/Kvs"
import AuthStoreContext from "contexts/AuthStoreContext"
import ParentSelect from "./ParentSelect"

const reportStore = ReportStore.create()

const Form = ({ store, values, handleSubmit, ...props }) => {
  const { statuses, kinds } = store.filtersOptions
  const [totalPlan, setTotalPlan] = useState(0)
  const [totalFact, setTotalFact] = useState(0)

  useEffect(() => {
    if (values.year && values.subdivision.id) {
      reportStore.fetch({ filter: { year: values.year - 1 } }).then(({ data }) => {
        const d = data.table.find((i) => i.subdivision.id === values.subdivision.id)
        if (d) {
          const { plan, fact } = d
          setTotalPlan(plan[plan.length - 1])
          setTotalFact(fact[fact.length - 1])
        }
      })
    }
  }, [values.year, values.subdivision.id])

  const salePlan = values.budget_items.reduce((a, b) => a + parseMoney(b.cost), 0)
  const { data: currentUser } = useContext(AuthStoreContext)

  useEffect(() => {
    if (currentUser.subdivision.id.toString() != "8") {
      props.setFieldValue("subdivision.id", currentUser.subdivision.id)
    }
  }, [store.isFetched])

  return (
    <form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Общие сведения">
          <br />
          {values.kind.value.toString() === "1" && <ParentSelect />}
          <RadioButtonGroup label="Статус" name="status.value">
            {statuses.map(({ label, value }) => (
              <RadioInput key={`status_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <RadioButtonGroup label="Бюджет" name="kind.value" disabled={currentUser.subdivision.id.toString() != "8"}>
            {kinds.map(({ label, value }) => (
              <RadioInput key={`kind_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          {values.kind.value.toString() === "0" && (
            <SubdivisionSelect name="subdivision.id" isDisabled={values.kind.value.toString() === "1"} />
          )}
          {values.kind.value.toString() === "1" && <SubdivisionsSelect by_year={values.year} />}
          <SelectInput
            name="year"
            label="Операционный год"
            options={[
              { label: "2020", value: 2020 },
              { label: "2021", value: 2021 },
              { label: "2022", value: 2022 },
              { label: "2023", value: 2023 },
              { label: "2024", value: 2024 },
              { label: "2025", value: 2025 },
              { label: "2026", value: 2026 },
              { label: "2027", value: 2027 }
            ]}
          />
          <TextInput name="comment" label="Комментарий" />
        </Tab>
        {/* {values.kind.value.toString() === "0" && (
          <Tab eventKey="plan" title="План продаж">
            <br />
            <CurrencyInput name="forecast" label="Итого  факт (прогноз) за предыдущий год" />
            <table className="table">
              <tr>
                <td style={{ width: "50%" }}>Итого план за предыдущий год</td>
                <td>{formatMoney(totalPlan)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Итого план продаж {values.year}</td>
                <td>{formatMoney(salePlan)}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Прирост {values.year} к плану предыдущего года</td>
                <td>{(((salePlan - totalPlan) / totalPlan) * 100).toFixed(3)}%</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Прирост {values.year} к факту предыдущего года</td>
                <td>{(((salePlan - parseMoney(values.forecast)) / totalFact) * 100).toFixed(3)}%</td>
              </tr>
            </table>
            <div style={{ overflowX: "scroll" }}>
              <table className="table table-bordered" style={{ width: "4300px" }}>
                <thead style={{ background: "#e5efdc" }}>
                  <tr>
                    <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                      План 1КВ{values.year}
                    </th>
                    <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                      План 2КВ{values.year}
                    </th>
                    <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                      План 3КВ{values.year}
                    </th>
                    <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                      План 4КВ{values.year}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ border: "1px solid white" }}>1КВ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>ЯНВ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>ФЕВ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>МАРТ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>2КВ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>АПР{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>МАЙ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>ИЮНЬ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>3КВ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>ИЮЛЬ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>АВГ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>СЕН{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>4КВ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>ОКТ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>НОЯ{values.year.toString().slice(2, 4)}</th>
                    <th style={{ border: "1px solid white" }}>ДЕК{values.year.toString().slice(2, 4)}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {values.budget_items.map((item, itemIndex) => (
                      <Fragment key={`item=${itemIndex}`}>
                        {itemIndex % 3 === 0 && (
                          <td style={{ verticalAlign: "middle", width: "200px" }}>
                            {formatMoney(
                              values.budget_items
                                .slice(itemIndex, itemIndex + 3)
                                .reduce((a, b) => a + parseMoney(b.cost), 0)
                            )}
                          </td>
                        )}
                        <td>
                          <CurrencyInput name={`budget_items.${itemIndex}.cost`} bare style={{ width: "100%" }} />
                        </td>
                      </Fragment>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
          </Tab>
        )} */}
        {values.kind.value.toString() === "1" && (
          <Tab eventKey="coefficient" title="Коэффициенты">
            <br />
            <PercentageInput name="coefficient_turnover" label="Ставка налога с оборота (план)" />
            <PercentageInput name="coefficient_turnover_fact" label="Ставка налога с оборота (факт)" />
            <PercentageInput name="coefficient_fot_one" label="Ставка налога на ФОТ 1" />
            <PercentageInput name="coefficient_fot_two" label="Ставка налога на ФОТ 2" />
            <PercentageInput name="coefficient_fot_three" label="Ставка налога на ФОТ 3" />
            <CurrencyInput name="coefficient_mrot" label="МРОТ" />
          </Tab>
        )}
      </Tabs>
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => formSubmitHandler(values, formikBag, "/budgets")
  }),
  observer
)(Form)
