import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetFotsStore from "stores/BudgetFotStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/BudgetFots/components/Tabs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import instance from "connection/instance"
import sortBy from "lodash.sortby"

const budgetStore = BudgetStore.create()
const budgetFotStore = BudgetFotsStore.create()

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetFotStore.fetchAll({ budget_id })
  }, [])

  const [fact, setFact] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_fots/fact`).then(({ data }) => {
      setFact(data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>ФОТ</BreadcrumbItem>
        <BreadcrumbItem active>План-факт</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "5300px" }}>
          <thead>
            <tr>
              <th colSpan={38}>План ФОТ</th>
            </tr>
            <tr>
              <th rowSpan={4}>#</th>
              <th rowSpan={3}>ФИО</th>
            </tr>
            <tr>
              <th colSpan={3}>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                <Fragment>
                  <th>Actual NET</th>
                  <th>ФОТ</th>
                  <th>O2</th>
                </Fragment>
              ))}
            </tr>
            <tr>
              <th>Итого</th>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                <Fragment>
                  <th>
                    {formatMoney(
                      budgetFotStore.data.filter(i => i.status.value.toString() == "1").reduce(
                        (a, b) =>
                          a +
                          parseMoney(b.budget_fot_items[month].fot) * 0.87 +
                          parseMoney(b.budget_fot_items[month].o_two),
                        0
                      )
                    )}
                  </th>
                  <th>
                    {formatMoney(
                      budgetFotStore.data.filter(i => i.status.value.toString() == "1").reduce((a, b) => a + parseMoney(b.budget_fot_items[month].fot), 0)
                    )}
                  </th>
                  <th>
                    {formatMoney(
                      budgetFotStore.data.filter(i => i.status.value.toString() == "1").reduce((a, b) => a + parseMoney(b.budget_fot_items[month].o_two), 0)
                    )}
                  </th>
                </Fragment>
              ))}
            </tr>
          </thead>
          {budgetFotStore.data.filter(i => i.status.value.toString() == "1").length > 0 && (
            <tbody>
              {sortBy(budgetFotStore.data.filter(i => i.status.value.toString() == "1"), (i) => i.user.name).map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.user.name}</td>
                  {values.budget_fot_items.map((item, itemIndex) => (
                    <Fragment key={`item=${itemIndex}`}>
                      <td>{formatMoney(parseMoney(item.fot) * 0.87 + parseMoney(item.o_two))}</td>
                      <td>{formatMoney(item.fot)}</td>
                      <td>{formatMoney(item.o_two)}</td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          <thead>
            <tr>
              <th colSpan={38}>Факт ФОТ</th>
            </tr>
            <tr>
              <th rowSpan={4}>#</th>
              <th rowSpan={3}>ФИО</th>
            </tr>
            <tr>
              <th colSpan={3}>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={3}>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                <Fragment>
                  <th>Actual NET</th>
                  <th>ФОТ</th>
                  <th>O2</th>
                </Fragment>
              ))}
            </tr>
            <tr>
              <th>Итого</th>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
                <Fragment>
                  <th>{formatMoney(fact.reduce((a, b) => a + b.costs[month].actual_net, 0))}</th>
                  <th>{formatMoney(fact.reduce((a, b) => a + b.costs[month].fot, 0))}</th>
                  <th>{formatMoney(fact.reduce((a, b) => a + b.costs[month].o_two, 0))}</th>
                </Fragment>
              ))}
            </tr>
          </thead>
          {fact.length > 0 && (
            <tbody>
              {fact.map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.name}</td>
                  {values.costs.map((cost, costIndex) => (
                    <Fragment key={`item=${costIndex}`}>
                      <td>{formatMoney(cost.actual_net)}</td>
                      <td>{formatMoney(cost.fot)}</td>
                      <td>{formatMoney(cost.o_two)}</td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  )
}

export default observer(List)
