import React from "react"
import ReactPaginate from "react-paginate"
import qs from "qs"
import { withRouter } from "react-router"
import { historyPushToStore } from "utils/historyPushWithStore"
import { observer } from "mobx-react"
import compose from "utils/compose"
import merge from "deepmerge"

const Paginate = ({ store, history, params = null, saveToStore = true, action = "fetch", additionalParams = {}, ...p }) => {
  const { page, pages } = store.meta.pagination
  const onPageChangeHandler = (page) => {
    const { search } = window.location
    if (!params) {
      params = qs.parse(search, { ignoreQueryPrefix: true })
      history.push({
        search: qs.stringify({ ...params, page })
      })
    }
    // history.push({
    //   search: qs.stringify({ ...params, page })
    // })
    const parsed = qs.parse(search, { ignoreQueryPrefix: true })
    let { b, ...additional } = parsed
    const a = params ? { ...params, ...b } : store.meta.filter
    store[action]({ ...params, ...merge(a, additionalParams), page })
  }
  if (pages === 1) {
    return null
  }
  return (
    <nav>
      <ReactPaginate
        activeClassName="active"
        breakClassName="page-link"
        containerClassName="pagination"
        disabledClassName="disabled"
        forcePage={page - 1}
        marginPagesDisplayed={5}
        nextClassName="page-item"
        nextLabel="&raquo;"
        nextLinkClassName="page-link"
        onPageChange={({ selected }) => {
          onPageChangeHandler(selected + 1)
          const { pathname, search } = window.location
          saveToStore && historyPushToStore(history, pathname, search)
        }}
        pageClassName="page-item"
        pageCount={pages}
        pageLinkClassName="page-link"
        pageRangeDisplayed={0}
        previousClassName="page-item"
        previousLabel="&laquo;"
        previousLinkClassName="page-link"
      />
    </nav>
  )
}

export default compose(withRouter, observer)(Paginate)
