import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import toOptions from "utils/toOptions"
import newToOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import { BudgetMotivation } from "./BudgetMotivation"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  kinds: types.array(newCustomEnum, []),
  rate_kinds: types.array(newCustomEnum, []),
  statuses: types.array(newCustomEnum, []),
  periodicities: types.array(newCustomEnum, []),
  bases: types.array(newCustomEnum, []),
  conditions: types.array(newCustomEnum, []),
  user_sale_plan_kinds: types.array(newCustomEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    kinds: [],
    rate_kind: [],
    statuses: [],
    periodicities: [],
    bases: [],
    conditions: [],
    user_sale_plan_kinds: []
  }
}

const initialState = {
  kind: { value: 0 },
  rate_kind: { value: 0 },
  status: { value: 0 },
  user: { id: null },
  periodicity: { value: null },
  basis: { value: null },
  condition: { value: null },
  user_sale_plan_kind: { value: 0 },
  comment: null,
  user_sale_plan: { id: null },
  is_use_coefficient: false,
  subdivision: { id: null },
  subdivisions: []
}

const BudgetMotivationStore = types
  .model("BudgetMotivationStore", {
    data: types.optional(BudgetMotivation, initialState),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get filtersOptions() {
      const { kinds, rate_kinds, statuses, periodicities, bases, conditions, user_sale_plan_kinds } =
        self.meta.filters_options
      return {
        kinds: newToOptions(kinds),
        rate_kinds: newToOptions(rate_kinds),
        statuses: newToOptions(statuses),
        periodicities: newToOptions(periodicities),
        bases: newToOptions(bases),
        conditions: newToOptions(conditions),
        user_sale_plan_kinds: newToOptions(user_sale_plan_kinds)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, budget_id, ...options } = params
      return instance
        .get(`/budgets/${budget_id}/budget_motivations/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { budget_id, ...options } = params
      return instance
        .get(`/budgets/${budget_id}/budget_motivations/meta`, { options })
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const { budget_id, ...data } = params
      return instance
        .post(`/budgets/${budget_id}/budget_motivations`, { data: prepare(data) })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id, budget_id, ...data } = params
      return instance
        .put(`/budgets/${budget_id}/budget_motivations/${id}`, { data: prepare(data) })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    },

    reset: () => {
      applySnapshot(self, {
        data: initialState,
        meta: self.meta
      })
    }
  }))

export default BudgetMotivationStore
